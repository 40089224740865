import * as React from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import ChatBotUi, { serverBaseURL } from './chatbotui';
import ChatIcon from '../components/svg/chat.png';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled from '@emotion/styled';
import Arrow from './svg/arrow';
import { Badge, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Cross from './svg/cross';
import FullScreenDialog from './mobilePoper';
import { useSearchParams } from 'react-router-dom';
import Chat from './svg/chat';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  inset: '0px -9px auto auto !important',
  [`& .${tooltipClasses?.tooltip}`]: {
    backgroundColor: 'transparent',
    // color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: '14px',
    marginRight: '0px !important',
    // border: '1px solid #dadde9',
  },
}));
let timeout;
let tooltipTimeout;
const ChatBotPoper = ({params, getTarget}) => {
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();
  const [searchParams] = useSearchParams();

  const isOpen = searchParams.get('isOpen') === 'true';
  const pathName = window?.location.pathname;
  const [anchorEl, setAnchorEl] = React.useState();

  const [isTooltip, setIsTooltip] = React.useState(false);
  const [chatData, setChatData] = React.useState([]);

  const [totalUnreadMessage, setTotalUnreadMessage] = React.useState(1);

  const handleClick = (event, ref) => {
    if (anchorEl) {
      window.parent.postMessage(
        {
          tracking: 'vitabot',
          event: 'chat_minimized',
        },
        '*'
      );
    } else {
      window.parent.postMessage(
        {
          tracking: 'vitabot',
          event: 'chat_opened',
        },
        '*'
      );
    }
    setAnchorEl(anchorEl ? null : ref || event?.currentTarget || null);
  };
  const boxRef = React.useRef();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  React.useEffect(() => {
    // timeout = setTimeout(() => {
    //   // handleClick(false, boxRef?.current);
    //   setIsTooltip(false);
    // }, 5000);

    tooltipTimeout = setTimeout(() => {
      setIsTooltip(true);
    }, 3000);

    return () => {
      clearTimeout(timeout);
      clearTimeout(tooltipTimeout);
      setIsTooltip(false);
    };
  }, []);

  React.useEffect(() => {
    let openChatBot =
      isOpen ||
      ['healthcare', 'fitness-exercise', 'food-nutrition'].includes(pathName);
  openChatBot && handleClick(null, openChatBot ? boxRef.current : false);
  }, []);

  React.useEffect(() => {
    if (anchorEl) {
      clearTimeout(timeout);
      clearTimeout(tooltipTimeout);
      setIsTooltip(false);
    }
    const search =
        typeof window !== "undefined" ? window.location.search.substring(1) : ""
    console.log(search);
    if (search && anchorEl !== undefined) {
      window.top?.postMessage(open, getTarget(params?.url))
    }
    return () => {
      clearTimeout(timeout);
      clearTimeout(tooltipTimeout);
      setIsTooltip(false);
    };
  }, [anchorEl]);

  const containerRef = React.useRef();

  React.useEffect(() => {
    const resizeListener = () => {
      if (containerRef?.current && window.innerHeight < 720) {
        containerRef.current.style.height = `${window.innerHeight - 130}px`;
      } else if (containerRef?.current) {
        containerRef.current.style.height = `${576}px`;
      }
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const isMobile = useMediaQuery('(max-width:450px)');

  return (
    <div>
      <Box
        aria-describedby={id}
        type="Box"
        onClick={handleClick}
        display={'inline-block'}
        ref={boxRef}
        id="test"
      >
        {
          <HtmlTooltip
            title={
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  svg: {
                    height: '20px',
                    width: '20px',
                    fontSize: '12px',
                    marginLeft: '-5px',
                  },
                }}
              >
                <Box
                  sx={{
                    padding: '16px',
                    fontSize: '14px',
                    background: main,
                    borderRadius: '25px',
                    display: 'flex',
                    justifyContent: 'center',
                    minWidth: '135px',
                    width: '100%',
                  }}
                >
                  Ask AI Supplement Assistant
                </Box>
                <Arrow color={main}/>
              </Box>
            }
            placement="left"
            open={isTooltip}
          >
            <Badge
              badgeContent={totalUnreadMessage}
              color="error"
              overlap="circular"
              sx={{ cursor: 'pointer' }}
            >
              {!open ? (
                // <img src={ChatIcon} style={{ height: '60px', width: '60px' }} />
                <Chat color={main} height='60px' width='60px'/>
              ) : (
                <div
                  style={{
                    height: '60px',
                    width: '60px',
                    background: main,
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Cross />
                </div>
              )}
            </Badge>
          </HtmlTooltip>
        }
      </Box>
      {isMobile ? (
        <FullScreenDialog open={open} handleClose={handleClick}>
          <ChatBotUi
            handleClick={handleClick}
            containerRef={null}
            setTotalUnreadMessage={setTotalUnreadMessage}
            chatData={chatData}
            setChatData={setChatData}
            params={params}
            getTarget={getTarget}
            // open={open}
          />
        </FullScreenDialog>
      ) : (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="top-end"
          ref={containerRef}
          sx={{
            mb: '10px !important',
            width: 'min(377px, 100% - 80px)',
            maxWidth: '377px',
            transition: 'auto',
          }}
        >
          <ChatBotUi
            handleClick={handleClick}
            containerRef={containerRef}
            setTotalUnreadMessage={setTotalUnreadMessage}
            chatData={chatData}
            setChatData={setChatData}
            params={params}
            getTarget={getTarget}
            // open={open}
          />
        </Popper>
      )}
    </div>
  );
};

export default ChatBotPoper;
